@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Chillax-Regular';
    src: url('./fonts/Chillax/Chillax-Regular.ttf') format('truetype');
         font-weight: 400;
         font-display: swap;
         font-style: normal;
  }

  @font-face {
    font-family: 'Gila';
    src: url('./fonts/Gila/Gila.ttf') format('truetype');
         font-weight: 400;
         font-display: swap;
         font-style: normal;
  }

  @layer base {
    html {
      font-family: 'Chillax-Regular' ;
    }
  }
  
  .rst__moveHandle
  {
    padding-top : 5px;
    padding-left: 1px;
    text-align: center;
  }
  .icon> .rst__moveHandle::before {
    content: url("../public/images/drag-drop-svgrepo-com.svg");
  }

